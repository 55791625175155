import { Injectable } from '@angular/core'
import { ProficloudService } from '../proficloud.service'

@Injectable({
  providedIn: 'root',
})
export class StateService {
  /**
   *   The broadcast channel is used to communicate 'organization switch events' across other open tabs.
   *
   *   It is the responsiblity of this service to:
   *
   *   A)  Broadcast when then organization has been switched (for other tabs to pick up)
   *   B)  To listen to this channel in case an event is broadcast by an other tag, and to react accordingly
   */
  orgSwitchedChannel = new BroadcastChannel('orgSwitchedChannel')

  loggedOutChannel = new BroadcastChannel('loggedOut')

  constructor(public proficloud: ProficloudService) {
    this.setupReactionToStateEvents()
    this.setupReactionToExternalOrgSwitch()
    this.setupReactionToExternalLogOut()
  }

  /**
   *   In case a an 'org-switch-event' happend in our tab, we want to broadcast this fact
   *   Likewise, when a 'log-out-event' happens in our tab, we also broadcast this fact
   */
  setupReactionToStateEvents() {
    this.proficloud.organisationSwitched$.subscribe((org) => {
      if (org) {
        this.orgSwitchedChannel.postMessage(org.organizationId)
      }
    })

    this.proficloud.loggedOut$.subscribe(() => {
      this.loggedOutChannel.postMessage('loggedOut')
    })
  }

  /**
   *    In case an 'org-switch-event' occured to anohter tab, we want to re-load
   */
  setupReactionToExternalOrgSwitch() {
    this.orgSwitchedChannel.onmessage = (eventMessage: MessageEvent<string>) => {
      console.log('Reloading')
      window.location.reload()
    }
  }

  /**
   *    In case an 'log-out-event' occured to anohter tab, we want to re-load
   */
  setupReactionToExternalLogOut() {
    this.loggedOutChannel.onmessage = (eventMessage: MessageEvent<string>) => {
      console.log('Reloading App')
      window.location.reload()
    }
  }
}
